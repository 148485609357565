/**
 * Obtem o contexto do sistema baseado no endereço atual da janela
 */
const obterContexto = () => {
    let pathname = window.location.pathname,
        indice = pathname.substring(1).indexOf('/'),
        contexto = pathname.substring(1, indice+1);
    return contexto;
};

/**
 * Obtem a url de destino das mensagens do JS
 */
const obterDestinoMensagem = () => {
    let queryParams = new URLSearchParams(window.location.search),
        refPortal = queryParams.get('ref-portal') || sessionStorage.getItem('ref-portal');

    if(refPortal) {
        sessionStorage.setItem('ref-portal', refPortal);
    } else {
        refPortal = obterDestinoDeOrigem(document.referrer);
    }

    return refPortal;
}

/**
 * Obtem a URL de destino das mensagens baseado na URL de origem.
 *
 * @param {String} origem url de origem do iFrame
 * @returns {String} url de destino das mensagens
 */
const obterDestinoDeOrigem = (origem) => {
    let destino = '';
    if(origem.indexOf('dev.uspdigital.usp.br') !== -1) destino = 'https://dev.uspdigital.usp.br';
    else if(origem.indexOf('labs.uspdigital.usp.br') !== -1) destino = 'https://labs.uspdigital.usp.br';
    else if(origem.indexOf('uspdigital.usp.br') !== -1) destino = 'https://uspdigital.usp.br';
    else if(origem.indexOf('localhost') !== -1) destino = 'http://localhost:8080';
    return destino;
};

/**
 * Otimiza o CSS de alguns elementos encontrados do documento passado.
 *
 * @param {Document} documento DOM a otimizar o CSS
 */
const otimizarCss = (documento) => {
    // Retira o padding padrão do conteúdo
    let conteudo = documento.getElementById('layout_conteudo');
    if(conteudo){
        conteudo.style.padding = '0';
    }
};

/**
 * Remove os elementos desnecessários para renderização no Portal (menus laterais, cabeçalhos, etc.)
 *
 * @param {Document} documento a ser otimizado
 */
const removerElementosDesnecessarios = (documento) => {
    // Tenta remover o Cabeçalho e menu padrão do sistema

    let cabecalho = documento.getElementById('layout_cabecalho'),
        menu = documento.getElementById('layout_menu');
    if(cabecalho){
        cabecalho.remove();
    }
    if(menu) {
        menu.remove();
    }
};

export { obterContexto, obterDestinoMensagem, otimizarCss, removerElementosDesnecessarios };