import { obterDestinoMensagem, otimizarCss, removerElementosDesnecessarios, obterContexto } from './funcoes';
import { enviarMensagemCarregado, enviarMensagemNavegacao } from './mensagens';

document.addEventListener('DOMContentLoaded', function(){
    if(window !== window.parent) {
        const urlAtual = window.location.pathname.substring(1).split('/')[1],
              query = window.location.search,
              destino = obterDestinoMensagem();

        if(destino){
            window.addEventListener('beforeunload', (event) => {
                enviarMensagemNavegacao(destino, urlAtual+query, obterContexto());
            });

            // remove o que não precisa
            removerElementosDesnecessarios(document);
            // otimiza o CSS do que for necessário
            otimizarCss(document);
            // Avisa o fim da otimização
            enviarMensagemCarregado(destino, urlAtual+query, obterContexto());
        }
    }
});