import { MENSAGEM_CARREGADA, MENSAGEM_NAVEGACAO, MENSAGEM_SUBMISSAO } from './constantes';

/**
 * Encia a mensagem de que existe uma navegação interna do sistema a ser considerada.
 *
 * @param {String} destino url de destino da mensagem.
 * @param {String} url url da navegação.
 * @param {String} contexto contexto do sistema atual.
 */
const enviarMensagemNavegacao = (destino, url, contexto) => {
    window.parent.postMessage({
        id: MENSAGEM_NAVEGACAO,
        url: url,
        contexto: contexto
    }, destino);
};

/**
 * Encia a mensagem de que existe um formulário sendo devidamente submetido.
 *
 * @param {String} destino url de destino da mensagem.
 * @param {String} url url da navegação.
 * @param {String} contexto contexto do sistema atual.
 */
const enviarMensagemSubmissao = (destino, url, contexto) => {
    window.parent.postMessage({
        id: MENSAGEM_SUBMISSAO,
        url: url,
        contexto: contexto
    }, destino);
};

/**
 * Envia a mensagem ao destino passado avisando que a otimização acabou com a URL atual passada.
 *
 * @param {String} destino url de destino da mensagem.
 * @param {String} url url atual de carregamento.
 */
const enviarMensagemCarregado = (destino, url, contexto) => {
    window.parent.postMessage({
        id: MENSAGEM_CARREGADA,
        url: url,
        contexto: contexto
    }, destino);
};

export { enviarMensagemCarregado, enviarMensagemNavegacao, enviarMensagemSubmissao };